export default /* glsl */`

#define PI 3.14159

uniform float iTime;
uniform vec4 iMouse;
varying vec2 vUv;

float vDrop(vec2 uv, float t) {
    uv.x = uv.x * 128.0; // H-Count
    float dx = fract(uv.x);
    uv.x = floor(uv.x);
    uv.y *= 0.05; // stretch
    float o = sin(uv.x * 215.4); // offset
    float s = cos(uv.x * 33.1) * .3 + .7; // speed
    float trail = mix(95.0, 35.0, s); // trail length
    float yv = fract(uv.y + t * s + o) * trail;
    yv = 1.0 / yv;
    yv = smoothstep(0.0, 1.0, yv * yv);
    yv = sin(yv * PI) * (s * 5.0);
    float d2 = sin(dx * PI);
    return yv * (d2 * d2);
}

void mainImage(out vec4 fragColor, in vec2 fragCoord) {
    vec2 p = (fragCoord - 0.5) * 2.0;
    float d = length(p) + 0.1;
    p = vec2(atan(p.x, p.y) / PI, 2.5 / d);
    if (iMouse.z > 0.5) p.y *= 0.5;
    float t = iTime * 0.4;
    vec3 col = vec3(1.55, 0.65, .225) * vDrop(p, t); // red
    col += vec3(0.55, 0.75, 1.225) * vDrop(p, t + 0.66); // blue
    col += vec3(0.45, 0.15, 0.425) * vDrop(p, t + 0.33); // green

    // Calculate the distance from the center of the UV coordinates
    float distanceFromCenter = length(vUv - 0.5);

    // Calculate the alpha value for smooth edge fade-out
    float alpha = 1.0 - smoothstep(0.1, 0.5, distanceFromCenter);

    // Output the final color with alpha
    fragColor = vec4(col * (d * d), alpha);
}

void main() {
    mainImage(gl_FragColor, vUv);
}
`;