import * as THREE from 'three';

export function createBackgroundScene() {
  const scene = new THREE.Scene();

  // Aurora vertex shader
  const auroraVertexShader = `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `;

  // Aurora fragment shader
  const auroraFragmentShader = `
    varying vec2 vUv;

    uniform float iTime;
    uniform vec3 color1;
    uniform vec3 color2;
    uniform vec3 color3;

    void main() {
      vec2 p = vUv * 2.0 - vec2(1.0); // Normalized coordinates

      // Create a diagonal gradient effect
      float gradient = (p.x + p.y + 1.0) / 3.0;

      // Blend colors based on gradient
      vec3 color = mix(color1, color2, gradient);
      color = mix(color, color3, gradient * 1.3);

      float alpha = gradient;

      gl_FragColor = vec4(color, alpha);
    }
  `;

  const auroraUniforms = {
    iTime: { value: 0 },
    color1: { value: new THREE.Color(0x130A14) }, // Dark grey
    color2: { value: new THREE.Color(0x3D244F) }, // Gray-purple
    color3: { value: new THREE.Color(0x834949) }, // Gray-red
  };

  const auroraMaterial = new THREE.ShaderMaterial({
    vertexShader: auroraVertexShader,
    fragmentShader: auroraFragmentShader,
    uniforms: auroraUniforms,
    transparent: true,
  });

  const auroraGeometry = new THREE.PlaneGeometry(3000, 3000);
  const auroraMesh = new THREE.Mesh(auroraGeometry, auroraMaterial);
  auroraMesh.position.set(0, 0, -500); // Positioning the aurora effect in the background
  scene.add(auroraMesh);

  // Stars
  const starGeometry = new THREE.BufferGeometry();
  const starMaterial = new THREE.PointsMaterial({
    color: 0xffffff,
    size: 1.5, // Increase this value to make the stars larger
    sizeAttenuation: true, // This makes sure that the star size diminishes with distance
    transparent: true,
    alphaTest: 0.1,
  });
  const starVertices = [];
  for (let i = 0; i < 3000; i++) {
    const x = (Math.random() - 0.5) * 2000;
    const y = (Math.random() - 0.5) * 2000;
    const z = (Math.random() - 0.5) * 2000 - 1000;
    starVertices.push(x, y, z);
  }
  starGeometry.setAttribute('position', new THREE.Float32BufferAttribute(starVertices, 3));

  const starOpacity = new Float32Array(3000).fill(1);
  starGeometry.setAttribute('alpha', new THREE.Float32BufferAttribute(starOpacity, 1));

  const stars = new THREE.Points(starGeometry, starMaterial);
  scene.add(stars);

  // Update function to animate aurora and stars
  const animate = () => {
    auroraUniforms.iTime.value += 0.01; // Animate the aurora effect

    // Animate stars moving upwards and blinking
    const positions = starGeometry.attributes.position.array;
    const alphas = starGeometry.attributes.alpha.array;
    for (let i = 1; i < positions.length; i += 3) {
      positions[i] += 0.2; // Move stars upwards
      if (positions[i] > 1000) {
        positions[i] = -1000; // Reset position when stars go out of view
      }

      // Randomly make some stars blink
      if (Math.random() > 0.98) {
        alphas[(i - 1) / 3] = Math.random(); // Random alpha value for blinking effect
      }
    }
    starGeometry.attributes.position.needsUpdate = true;
    starGeometry.attributes.alpha.needsUpdate = true;

    requestAnimationFrame(animate);
  };
  animate();

  return { scene, stars };
}