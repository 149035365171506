import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import vertexGlowShader from '../shaders/textGlowVertex.glsl';
import fragmentGlowShader from '../shaders/textGlowFragment.glsl';
import GUI from 'lil-gui';

export function createObject1(camera) {
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
  };
  
  const objectGroup = new THREE.Group();

  // Load font and create 3D text
  const loader = new FontLoader();
  loader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', function (font) {
    const textGeometry = new TextGeometry('2009 Global', {
      font: font,
      size: 15,
      height: 1,
      curveSegments: 12,
      bevelEnabled: true,
      bevelThickness: 1,
      bevelSize: 0.5,
      bevelSegments: 5
    });

    const textMaterial = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      specular:  0x050505,
      shininess: 10,
      side: THREE.DoubleSide
    });

    const glowMaterial = new THREE.ShaderMaterial({
      uniforms: {
        iTime: { value: 0 },
        iResolution: { value: new THREE.Vector3(window.innerWidth, window.innerHeight, 1) },
      },
      vertexShader: vertexGlowShader,
      fragmentShader: fragmentGlowShader,
      // side: THREE.FrontSide,
      // blending: THREE.AdditiveBlending,
      transparent: true,
      // depthWrite: false
    });

    const glowMesh = new THREE.Mesh(textGeometry, glowMaterial);
    textGeometry.computeBoundingBox(); // Compute the bounding box
    const boundingBox = textGeometry.boundingBox;
    const textWidth = boundingBox.max.x - boundingBox.min.x;

    // Function to center the text
    function centerText() {
      textGeometry.computeBoundingBox(); // Compute the bounding box
      const boundingBox = textGeometry.boundingBox;
      const textWidth = boundingBox.max.x - boundingBox.min.x;

      // Adjust position based on the camera aspect ratio and viewport size
      const aspectRatio = window.innerWidth / window.innerHeight;
      const scale = Math.min(window.innerWidth, window.innerHeight) / 1000;
      glowMesh.scale.set(scale, scale, scale);
      glowMesh.position.set(-textWidth / 2 * scale, 0, 40);
    }

    // Initial call to center the text
    centerText();
    glowMesh.position.set(-textWidth / 3.8, 0, 40);
    // console.log(-textWidth);
    objectGroup.add(glowMesh);

    objectGroup.userData.glowMesh = glowMesh;

    // Define the resize function
    // Function to center the text
    function centerText() {
      textGeometry.computeBoundingBox(); // Compute the bounding box
      const boundingBox = textGeometry.boundingBox;
      const textWidth = boundingBox.max.x - boundingBox.min.x;

      // Adjust position based on the camera aspect ratio and viewport size
      const aspectRatio = window.innerWidth / window.innerHeight;
      const scale = Math.min(window.innerWidth, window.innerHeight) / 1000;
      glowMesh.scale.set(scale, scale, scale);
      glowMesh.position.set(-textWidth / 2 * scale, 0, 40);
    }

    // Initial call to center the text
    centerText();

    // Add event listener for window resize
    window.addEventListener('resize', () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      objectGroup.userData.resize(width, height);
      centerText(); // Re-center the text on resize
    });

  });

  // // Add lighting to the object group
  const ambientLight = new THREE.AmbientLight(0x404040); // Soft white ambient light
  ambientLight.position.set(0, -1000, 200);
  objectGroup.add(ambientLight);

  const directionalLight = new THREE.DirectionalLight(0xffffff, 100);
  directionalLight.position.set(720, -115, 180); // Position the light in front of the text
  objectGroup.add(directionalLight);

  // Add GUI controls for the astronaut
  // const gui = new GUI();
  // const modelFolder = gui.addFolder('World Group');
  // modelFolder.add(ambientLight.position, 'x', -2000, 2000).name('X Position');
  // modelFolder.add(ambientLight.position, 'y', -2000, 2000).name('Y Position');
  // modelFolder.add(ambientLight.position, 'z', -2000, 2000).name('Z Position');
  // modelFolder.add(directionalLight.position, 'x', -2000, 2000).name('X Position');
  // modelFolder.add(directionalLight.position, 'y', -2000, 2000).name('Y Position');
  // modelFolder.add(directionalLight.position, 'z', -2000, 2000).name('Z Position');

  // modelFolder.open();


   // Raycaster setup
  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();

  // Particle system setup
  const particleCount = 1000;
  const particles = new THREE.BufferGeometry();
  const particlePositions = new Float32Array(particleCount * 3);
  const particleMaterial = new THREE.PointsMaterial({ color: 0xff00ff, size: 0.2 });

  for (let i = 0; i < particleCount; i++) {
    particlePositions[i * 3] = Math.random() * 2 - 1;
    particlePositions[i * 3 + 1] = Math.random() * 2 - 1;
    particlePositions[i * 3 + 2] = Math.random() * 2 - 1;
  }

  particles.setAttribute('position', new THREE.BufferAttribute(particlePositions, 3));
  const particleSystem = new THREE.Points(particles, particleMaterial);
  objectGroup.add(particleSystem);

  // Event listener for mouse movement
  window.addEventListener('mousemove', (event) => {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObject(objectGroup.userData.glowMesh);

    if (intersects.length > 0) {
      const intersect = intersects[0];
      const particlesPositions = particles.attributes.position.array;

      for (let i = 0; i < particleCount; i++) {
        particlesPositions[i * 3] = intersect.point.x + (Math.random() - 0.5) * 0.5;
        particlesPositions[i * 3 + 1] = intersect.point.y + (Math.random() - 0.5) * 0.5;
        particlesPositions[i * 3 + 2] = intersect.point.z + (Math.random() - 0.5) * 0.5;
      }

      particles.attributes.position.needsUpdate = true;
    }
  });

  return objectGroup;
}