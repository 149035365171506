import * as THREE from 'three';
import portalVertex from '../shaders/portalVertex.glsl';
import portalFragment from '../shaders/portalFragment.glsl';
import debrisVertex from '../shaders/debrisVertex.glsl';
import debrisFragment from '../shaders/debrisFragment.glsl';
// import warpingVertex from '../shaders/warpingVertex.glsl';
// import warpingFragment from '../shaders/warpingFragment.glsl';
import tunnelVertex from '../shaders/tunnelVertex.glsl';
//import tunnelFragment from '../shaders/tunnelFragment.glsl';
import tunnelFragment from '../shaders/vdropFragment.glsl';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import GUI from 'lil-gui';
//import { Wireframe } from 'three/examples/jsm/Addons.js';

export function createObject3() {
  const objectGroup = new THREE.Group();

  // Load the astronaut model
  const loader = new GLTFLoader();
  loader.load('models/astronaut/scene.gltf', (gltf) => {
    const model = gltf.scene;
    model.position.set(0, 1962,84);
    model.rotation.y = Math.PI; // Rotate 180 degrees around Y-axis to face the back
    objectGroup.add(model);

    // Add GUI controls for the astronaut
    // const gui = new GUI();
    // const modelFolder = gui.addFolder('Astronaut Model');
    // modelFolder.add(model.position, 'x', -2000, 2000).name('X Position');
    // modelFolder.add(model.position, 'y', -2000, 2000).name('Y Position');
    // modelFolder.add(model.position, 'z', -2000, 2000).name('Z Position');
    // modelFolder.add(model.rotation, 'x', -Math.PI, Math.PI).name('X Rotation');
    // modelFolder.add(model.rotation, 'y', -Math.PI, Math.PI).name('Y Rotation');
    // modelFolder.add(model.rotation, 'z', -Math.PI, Math.PI).name('Z Rotation');
    // modelFolder.open();
  });

  const aspect = window.innerWidth / window.innerHeight;
  const height = 1;
  const width = height * aspect;

  // Circle geometry with a radius that matches your screen aspect ratio
  const radius = Math.min(window.innerWidth, window.innerHeight) / 2;
  const geometry = new THREE.CircleGeometry(radius, 64);

  // Shader material
  const shaderMaterial = new THREE.ShaderMaterial({
    uniforms: {
      iTime: { value: 0 },
      iResolution: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) }
    },
    vertexShader: tunnelVertex,
    fragmentShader: tunnelFragment,
    transparent: true,
    side: THREE.DoubleSide
  });

  // Shader material
  // const shaderMaterial2 = new THREE.ShaderMaterial({
  //   uniforms: {
  //     iTime: { value: 0 },
  //     iResolution: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) }
  //   },
  //   vertexShader: warpingVertex,
  //   fragmentShader: warpingFragment,
  //   transparent: true,
  //   side: THREE.DoubleSide
  // });

  const portalMesh = new THREE.Mesh(geometry, shaderMaterial);
  portalMesh.position.set(0, 400, -100); // Adjust the position as needed

  objectGroup.add(portalMesh);

  // const gui = new GUI();
  // const modelFolder = gui.addFolder('Warping effect');
  // modelFolder.add(portalMesh.position, 'x', -2000, 2000).name('X Position');
  // modelFolder.add(portalMesh.position, 'y', -2000, 2000).name('Y Position');
  // modelFolder.add(portalMesh.position, 'z', -2000, 2000).name('Z Position');
  // modelFolder.add(portalMesh.rotation, 'x', -Math.PI, Math.PI).name('X Rotation');
  // modelFolder.add(portalMesh.rotation, 'y', -Math.PI, Math.PI).name('Y Rotation');
  // modelFolder.add(portalMesh.rotation, 'z', -Math.PI, Math.PI).name('Z Rotation');
  // modelFolder.open();

  // Debris Geometry
  // const debrisGeometry = new THREE.SphereGeometry(0.05, 16, 16);
  // const debrisMaterial = new THREE.ShaderMaterial({
  //   uniforms: {
  //     iTime: { value: 0 }
  //   },
  //   vertexShader: debrisVertex,
  //   fragmentShader: debrisFragment
  // });

  // // Create multiple debris pieces
  // const debrisGroup = new THREE.Group();
  // for (let i = 0; i < 100; i++) {
  //   const debris = new THREE.Mesh(debrisGeometry, debrisMaterial);
  //   debris.position.set(
  //     (Math.random() - 0.5) * 10,
  //     (Math.random() - 0.5) * 10,
  //     (Math.random() - 0.5) * 10
  //   );
  //   debrisGroup.add(debris);
  // }
  // debrisGroup.position.set(0, 1950, -200); // Same position as portal
  // objectGroup.add(debrisGroup);

  // Lighting
  const ambientLight = new THREE.AmbientLight(0x404040);
  objectGroup.add(ambientLight);

  const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
  objectGroup.add(directionalLight);

  const hemisphereLight = new THREE.HemisphereLight(0x7444ff, 0xff00bb, 0.5);
  objectGroup.add(hemisphereLight);

  const pointLight = new THREE.PointLight(0x7444ff, 1, 100);
  pointLight.position.set(0, 3, 4);
  objectGroup.add(pointLight);

  // Animation function to update shader time
  function animatePortal() {
    shaderMaterial.uniforms.iTime.value += 0.01; // Adjust speed as necessary
    //portalMesh.rotation.z += 0.01; // Adjust rotation speed as necessary

    // const elapsedTime = clock.getElapsedTime();
    // // Update debris shader time uniform
    // debrisMaterial.uniforms.iTime.value = elapsedTime;
  }

  // Add the animation function to the object's userData
  objectGroup.userData.animateFunctions = [animatePortal];

  // Handle window resizing
  window.addEventListener('resize', () => {
    const aspect = window.innerWidth / window.innerHeight;
    const height = 1;
    const width = height * aspect;

    portalMesh.geometry.dispose();
    portalMesh.geometry = new THREE.PlaneGeometry(width, height, 64, 64);
    shaderMaterial.uniforms.iResolution.value.set(window.innerWidth, window.innerHeight);
  });

  // const boxHelper = new THREE.BoxHelper(objectGroup, 0xff0000);
  // objectGroup.add(boxHelper);

  return objectGroup;
}