import '../styles/style.scss';
import { initApp } from './app.js';

document.addEventListener('DOMContentLoaded', () => {
  const canvas = document.querySelector('.webgl');
  if (!canvas) {
    console.error('Canvas element not found');
    return;
  }

  initApp(canvas);

  
  
});

// function updateThreeJSScene(index) {
//   gsap.to(camera.position, {
//       duration: 1.5,
//       ease: 'power2.inOut',
//       y: -objectsDistance * index
//   });

//   gsap.to(sectionMeshes[index].rotation, {
//       duration: 1.5,
//       ease: 'power2.inOut',
//       x: '+=6',
//       y: '+=3',
//       z: '+=1.5'
//   });
// }

const menuMobileButton = document.querySelector('#header-nav-menu-button');
menuMobileButton.addEventListener('click', function() {
  console.log('mobile');
  this.classList.toggle('active');
  const elementsToToggle = document.querySelectorAll('#menu-mobile'); 
  elementsToToggle.forEach(element => {
    element.classList.toggle('hidden');
  });
});

const worldwide = document.querySelector('.worldwide');
if (worldwide) {
  new PureCounter({
    selector: '.worldwide',	
    start: 6820000000, 			            
    end: 6830000000, 			           
    duration: 3, 	               
    delay: 10, 			            
    once: true, 		           
    repeat: false, 		            
    decimals: 0, 		            
    legacy: true,                   
    filesizing: false, 	            
    currency: false, 	            
    separator: true, 	          
  });
}





window.addEventListener('load', () => {
  const preloader = document.getElementById('preloader');
  if (preloader) {
    preloader.style.display = 'none';
  }
});



    
    
   
